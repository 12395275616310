import { useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import { Box } from '@mui/material';
import useRoles from 'api/useRoles';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { useFormik } from 'formik';
import * as yup from 'yup';

import usePublisher from 'api/usePublisher';
import useUsers from 'api/useUsers';
import { User, UserRole, UserRoleV2 } from 'common/contracts';
import {
  EButtonColor,
  ENotificationType
} from 'constants/enums';
import { useNotifications } from 'hooks/useNotifications';
import { AuthSliceState } from 'store/store.types';
import { required } from 'utils/errorsTextHelper';
import { capitalizeFirstLetter } from 'utils/formattingUtil';
import { permissionsUtil } from 'utils/permissionsUtil';
import ActionButton from '../../ActionButton/ActionButton';
import DataTable from '../../DataTable/DataTable';
import DialogModal from '../../Dialog/Dialog';

import UserSettingsDrawer from './UserSettingsDrawer';
import { getUsersColumns } from './utils';

import './style.scss';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const UsersSettings = () => {
  const [addUserDrawerOpen, setAddUserDrawerOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dialogUserId, setDialogUserId] = useState<string>('');
  const { getRoles } = useRoles();

  const v2UserRolesOptions = useMemo(() => {
    return getRoles.data?.map((role: UserRoleV2) => {
      return {
        value: role.roleId,
        content: capitalizeFirstLetter(role.roleName),
        key: role.roleId
      };
    });
  }, [getRoles.data]);

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const users = useUsers({ publisherId: currentPublisherId, enableFeatureFlags: false });
  const { enqueueSnackbar } = useNotifications();
  const publisherUsers = usePublisher(
    currentPublisherId || undefined
  ).getUsersByPublisher;
  const [isChangeRoleDialogOpen, setIsChangeRoleDialogOpen] = useState(false);
  const [selectedRoleChange, setSelectedRoleChange] =
    useState<UserRole | null>();
  const [selectedUser, setSelectedUser] =
    useState<Pick<User, 'userId' | 'name'>>();
  const openDeleteDialog = (userId: string) => {
    setDialogUserId(userId);
    setDeleteDialogOpen(true);
  };

  const deleteUser = (id: string) => {
    users.deleteUser.mutate(id, {
      onSuccess: (data: any) => {
        publisherUsers.refetch();
        enqueueSnackbar(`User has been deleted`, ENotificationType.SUCCESS);
      },
      onError: (data: any) => {
        enqueueSnackbar(
          `Something went wrong while trying to delete the user`,
          ENotificationType.ERROR
        );
      }
    });
  };

  const deleteUserHandler = () => {
    deleteUser(dialogUserId);
  };

  const handleChangeUserRole = (e: any, id: string, name: string) => {
    setSelectedRoleChange(e.target.value);
    setSelectedUser({
      userId: id,
      name
    });
    setIsChangeRoleDialogOpen(true);
  };

  const columnsV2 = useMemo(() => {
    return permissionsUtil.isAdmin()
      ? getUsersColumns(
          handleChangeUserRole,
          permissionsUtil.isAdmin(),
          openDeleteDialog,
          v2UserRolesOptions
        )
      : getUsersColumns(
          handleChangeUserRole,
          false,
          openDeleteDialog,
          v2UserRolesOptions
        );
  }, [v2UserRolesOptions]);

  let addUserSchema = yup.object().shape({
    name: yup.string().required(required('Name')),
    email: yup.string().required(required('Email')).email('Enter a valid email')
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    submitForm,
    resetForm,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      role: 'admin'
    },
    validationSchema: addUserSchema,
    onSubmit: async (formData) => {
      setAddUserDrawerOpen(false);
      formData.name = formData.name.trim();
      (formData as any).publisherId = currentPublisherId ?? '';
      (formData as any).email = formData.email.toLowerCase().trim();
      users.addUser.mutate(formData, {
        onSuccess: () => {
          resetForm();
          publisherUsers.refetch();
          enqueueSnackbar(
            `${formData.name} has been invited to AppCharge dashboard`
          );
        },
        onError: (error: any) => {
          resetForm();
          enqueueSnackbar(
            error.response.data.message ??
              `Something went wrong while trying to invite a user`,
            ENotificationType.ERROR
          );
        }
      });
    }
  });

  const changeUserPermission = (userId: string, role: UserRole | string) => {
    users.updateRoleV2.mutate(
        {
          roleId: role as string,
          userId
        },
        {
          onSuccess: () => {
            enqueueSnackbar(`User permission has been updated`);
            publisherUsers.refetch();
          },
          onError: () => {
            enqueueSnackbar(
                `Something went wrong while trying to update user permission`,
                ENotificationType.ERROR
            );
          },
          onSettled: () => {
            setIsChangeRoleDialogOpen(false);
          }
        }
    );
  };
  return (
    <Box className="user-settings-container">
      <DataTable
        columns={columnsV2}
        rows={
          !publisherUsers.isLoading && !getRoles.isLoading
            ? (publisherUsers.data as User[]).map((user) => {
                return {
                  _id: user.userId,
                  name: user.name,
                  lastActive: user.lastLogin,
                  email: user.email,
                  role: user?.role ? capitalizeFirstLetter(user.role) : '',
                  active: !!user.lastLogin
                };
              })
            : []
        }
        loading={publisherUsers.isLoading}
        checkboxSelection
      />
      {!publisherUsers.isLoading && (
        <Box paddingTop={2}>
          <ActionButton
            variant="outlined"
            text="Add User"
            onClick={() => setAddUserDrawerOpen(true)}
          />
        </Box>
      )}
      <UserSettingsDrawer
        addUserDrawerOpen={addUserDrawerOpen}
        setAddUserDrawerOpen={setAddUserDrawerOpen}
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
        touched={touched}
        errors={errors}
        v2UserRolesOptions={v2UserRolesOptions}
        isValid={isValid}
        dirty={dirty}
        submitForm={submitForm}
      />
      <DialogModal
        isOpen={isChangeRoleDialogOpen}
        headline="Change User Role"
        text={
          <>
            Are you sure you want to change <b>{selectedUser?.name}</b> role to{' '}
            <b>{capitalizeFirstLetter(selectedRoleChange ?? '')}</b>?
          </>
        }
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setIsChangeRoleDialogOpen(false);
            }
          },
          {
            text: 'Change Role',
            color: EButtonColor.PRIMARY,
            variant: 'contained',
            func: () => {
              changeUserPermission(selectedUser!.userId, selectedRoleChange!);
            }
          }
        ]}
        closeDialog={() => setIsChangeRoleDialogOpen(false)}
      />
      <DialogModal
        isOpen={isDeleteDialogOpen}
        headline="Delete User"
        text="Are you sure you want to delete this user?"
        buttons={[
          {
            text: 'Cancel',
            color: EButtonColor.SECONDARY,
            variant: 'outlined',
            func: () => {
              setDeleteDialogOpen(false);
            }
          },
          {
            text: 'Delete',
            color: EButtonColor.ERROR,
            variant: 'contained',
            func: () => {
              deleteUserHandler();
              setDeleteDialogOpen(false);
            }
          }
        ]}
        closeDialog={() => setDeleteDialogOpen(false)}
      />
    </Box>
  );
};

export default UsersSettings;
